import React from 'react';
import './index.css';
//import App from './App';
import { createRoot } from "react-dom/client";
//import {BrowserRouter} from "react-router-dom";

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <iframe src={"https://anfrage.tuning-datenbank.de/iframe/mpp"} style={{
    border:0, outline:0, width: "100%", height: "100vh",
  }}/>
);
/*
root.render(
  <BrowserRouter>

      <App />
  </BrowserRouter>
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
